import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import WhiteBox from "../../components/whiteBox"
import BulletList from "../../components/bulletList"

import styles from "../../styles/pages/berenson-advisory.module.scss"
import pageStyles from "../../styles/pages/page-styles.module.scss"

const WhiteBoxContent = ({ title, subtext }) => (
  <div className={styles.boxContainer}>
    <WhiteBox shadowLarge>
      <div className={styles.boxContentContainer}>
        <h3>{title}</h3>
        <p style={{ margin: 0, opacity: 0.8 }}>{subtext}</p>
      </div>
    </WhiteBox>
  </div>
)

const BerensonAdvisory = ({ location, data }) => {
  var page = data.contentfulAdvisoryFocusPage
  return (
    <Layout location={location.pathname}>
      <SEO title="Berenson Advisory" />
      {/*<BackgroundLines yPosition={370} />*/}
      <div className={pageStyles.pageContentContainer}>
        <h2 className={pageStyles.pageTitle}>Focus</h2>
        <div className={styles.boxFlexContainer}>
          {page.threeBoxesSection.map((box, key) => (
            <WhiteBoxContent
              title={box.boxTitle}
              subtext={box.boxSubtext.boxSubtext}
              key={key}
            />
          ))}
        </div>
        <div className={styles.contentSection}>
          <h3>{page.menaSectionTitle}</h3>
          <div className={styles.contentFlexContainer}>
            <div className={styles.contentFlexItem}>
              <p style={{ opacity: 0.8 }}>
                {page.menaSectionParagraph1.menaSectionParagraph1}
              </p>
            </div>
            <div className={styles.contentFlexItem}>
              <p style={{ opacity: 0.8 }}>
                {page.menaSectionParagraph2.menaSectionParagraph2}
              </p>
              <div className={styles.bulletFlexContainer}>
                <BulletList>
                  {page.listSection1.map((item, key) => (
                    <li key={key}>{item.listItemTitle}</li>
                  ))}
                </BulletList>
                <BulletList>
                  {page.listSection2.map((item, key) => (
                    <li key={key}>{item.listItemTitle}</li>
                  ))}
                </BulletList>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BerensonAdvisory

export const query = graphql`
  query FocusPage {
    contentfulAdvisoryFocusPage {
      threeBoxesSection {
        boxTitle
        boxSubtext {
          boxSubtext
        }
      }
      menaSectionTitle
      menaSectionParagraph1 {
        menaSectionParagraph1
      }
      menaSectionParagraph2 {
        menaSectionParagraph2
      }
      listSection1 {
        listItemTitle
      }
      listSection2 {
        listItemTitle
      }
    }
  }
`
